/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import '../App.scss';
import Header from '../components/Header';
import Banner from '../components/Main';
import Footer from '../components/Footer';

// eslint-disable-next-line func-names
const Home = function () {
  useEffect(() => {
    const element = document.querySelector('.menu-icon');
    const sidemenu = document.querySelector('.inner');
    let sidebarstatus = false;

    const ToggleSidebar = () => {
      if (sidebarstatus === false) {
        sidebarstatus = true;
        document.querySelector('.header-links').classList.add('show');
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        sidebarstatus = false;
        document.querySelector('.header-links').classList.remove('show');
        document.querySelector('body').style.overflow = 'auto';
      }
    };
    element.addEventListener('click', ToggleSidebar);
    sidemenu.addEventListener('click', ToggleSidebar);
    if ($(window).width() > 1200) {
      $('header ul.main-links li a').click(function headerClick(e) {
        e.preventDefault();
        $('html,body').scrollTop = $($.attr(this, 'href')).offset().top + 500;
        window.scrollTo(0, $($.attr(this, 'href')).offset().top - 100);
      });
    } else {
      $('header ul.main-links li a').click(function headerLiClick(e) {
        e.preventDefault();
        $('html,body').scrollTop = $($.attr(this, 'href')).offset().top + 500;
        window.scrollTo(0, $($.attr(this, 'href')).offset().top - 100);
      });
    }
    $(window).scroll(() => {
      const scroll = $(window).scrollTop();
      if (scroll >= 100) {
        $('header').addClass('fixed-header');
      } else {
        $('header').removeClass('fixed-header');
      }
    });
  });
  return (
    <div className="App">
      <Header />
      <Banner />
      <Footer />
    </div>
  );
};

export default Home;

/* eslint-disable max-len */
import React from 'react';
import '../App.scss';

// eslint-disable-next-line func-names
const Banner = function () {
  return (
    <>
      <section className="banner" id="banner">
        <div className="container">
          <div className="banner-wrapper">
            <div className="banner-desc">
              <h1>Kreditvurdering af virksomheder</h1>
              <p>Få gode råd og vejledning om, hvad du som virksomhed skal være opmærksom på, når du løbende skal lave en kreditvurdering af dine debitorer.</p>
            </div>
            <div className="banner-img">
              <img src="assets/img/banner.svg" alt="banner-img" />
            </div>
          </div>
        </div>
      </section>
      <section className="b2b-banner" id="b2b-branchen">
        <div className="container">
          <div className="banner-desc">
            <h2>
              Hvad betyder
              <br />
              kreditvurdering i B2B-branchen
            </h2>
            <img src="assets/img/hvad-img.jpg" alt="Hvad betyder" />
            <div className="b2b-text">
              <p>Som virksomhed i B2B-branchen er en løbende kreditvurdering af dine debitorer afgørende, idet et uundgåeligt og væsentligt element i det at give kredit er risikoen for at tabe udestående penge på svage virksomheder samt dårlige betalere. En kreditvurdering bliver da af afgørende betydning for at opfange potentielt dårlige betalere og skabe en forudsigelighed i de forventede tab på din virksomheds tilgodehavender.</p>
              <p>Ved løbende at foretage kreditvurderinger af dine debitorer, minimeres din virksomheds kreditrisiko. For med løbende kreditvurderinger kan tab på debitorer reduceres betydeligt.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="why-score" id="hvorfor">
        <div className="container">
          <div className="why-score-wrapper">
            <div className="why-score-desc">
              <h2>Hvorfor er hyppige kreditvurderinger vigtige for B2B-virksomheder? </h2>
              <div className="why-score-img">
                <img src="assets/img/kort.svg" alt="kort" />
              </div>
              <div className="virksomheder-left">
                <p>Kreditvurderingen er dels en vurdering af risikoen for, at en potentiel debitor ikke indfrier sine forpligtelser, men kreditvurderingen udtrykker også implicit sandsynligheden for, at en given debitor går konkurs.  </p>
                <p>Ved løbende at få udarbejdet kreditvurderinger har din virksomhed altid et opdateret indblik i debitors evne til at kunne betale, og en implicit viden om den potentielle debitor er ved at gå konkurs eller ej. </p>
                <p>Ved at få foretaget hyppige kreditvurderinger beskyttes virksomheden dermed mod at drive forretning med erhvervskunder, der ikke kan indfri givne kreditforpligtelser. </p>
              </div>
              <div className="virksomheder-right">
                <p>Yderligere giver en løbende kreditvurdering et indblik i, om et samarbejde er sundt, eller om det er på vej i den forkerte retning.  </p>
                <p>Det kan som virksomhed være svært at navigere rundt i de forskellige parametre, man skal tage højde for i en kreditvurdering, men der er en række ting du i din virksomhed kan tage højde for. </p>
                <a href="https://risika.com/" title="Risika" target="_blank" rel="noreferrer">risika.com</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ten-tips">
        <div className="container">
          <div className="ten-tips-wrapper">
            <div className="ten-tips-desc">
              <h2>10 ting du som virksomhed skal holde øje med, når du udfører kreditvurdering</h2>
              <p>I forbindelse med udarbejdelsen af en kreditvurdering er der en række vitale forhold, som bør undersøges, idet disse kan have betydelig indflydelse på kreditvurderingen. Vi har herunder samlet de 10 nævneværdige:</p>
            </div>
            <div className="ten-tips-img kr-img" id="10-gode-rad">
              <img src="assets/img/10ting.png" alt="10ting" />
            </div>
          </div>
        </div>
      </section>
      <section className="tips-step">
        <div className="container">
          <div className="tip-step-wrapper">
            <div className="tip-step-item" id="virksomhedens">
              <div className="tip-step-box">
                <div className="tip-step-header" id="accr-head-1">
                  <span>
                    1. Virksomhedens alder
                  </span>
                </div>
                <div className="tip-step-content" id="accr-1">
                  <p>Kreditvurderingen bør altid inddrage virksomhedens alder, idet nystartede virksomheder er behæftet med større risiko end en virksomhed, som har været etableret på markedet i en længere årrække, der ikke imødekommer selvsamme markedsrisici. Derudover er datatilgængeligheden begrænset for nystartede virksomheder, da virksomheden ikke har indleveret årsregnskaber kontinuerligt, som kan give indblik i udviklingen i virksomhedens profitabilitet og finansielle styrke. Typisk vil en udvikling på 3-5 år give tilfredsstillende analysemuligheder.</p>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="regnskabsdata">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    2. Se nærmere på virksomhedens regnskabsdata
                  </span>
                </div>
                <div className="tip-step-content" id="accr-2">
                  <p>Virksomhedens regnskabs- og nøgletal er en vital indfaldsvinkel til, hvordan det går virksomheden. Data fra virksomhedernes årsregnskaber danner grobund for vurdering af sandsynligheden for konkurs. Sådanne finansielle data kan også give indblik i, om virksomheden:</p>
                  <ul>
                    <li>Har virksomheden tilsidesat kapitalreserver til at dække både uventede begivenheder?</li>
                    <li>Er omsætningsaktiverne tilstrækkelige til dækning af kortfristet gæld?</li>
                    <li>Er driftskapitalen positiv?</li>
                    <li>Er årsresultatet konstant stigende?</li>
                    <li>Kan virksomheden finansiere sine gældsforpligtelser under de mest ugunstige renteforhold?</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="struktur">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    3. Virksomhedens struktur
                  </span>
                </div>
                <div className="tip-step-content" id="accr-3">
                  <ul>
                    <li>Hvordan er virksomhedens ejerkreds? </li>
                    <li>Har virksomhedens ejerkreds en betydelig konkurshistorik? </li>
                    <li>Er virksomheden helt ny og har ledelsen ingen konkurser bag sig, så overvej hvilken branche virksomheden befinder sig i, og om kompetencerne i ledelsen er tilstrækkelige.  </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="likviditet">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    4. Virksomhedens likviditet
                  </span>
                </div>
                <div className="tip-step-content" id="accr-4">
                  <p>Likviditetsnøgletallene knytter sig til virksomhedens kortsigtede betalingsevne, og er et udtryk for virksomhedens kortsigtede finansielle styrke, der grundlæggende vurderes ud fra, om der er tilstrækkelige mængder omsættelig kapital til at indfri korte gældsforpligtelser. Likviditetsnøgletallene udregnes som et forhold i mellem omsætningsaktiverne og de kortfristede gældsforpligtelser. Det helt centrale nøgletal i vurderingen af virksomhedens likviditet er likviditetsgraden, der udregnes som omsætningsaktiverne delt med den kortfristede gæld. Et tilfredsstillende niveau for likviditetsgraden ligger i omegnen af 150% således, at samtlige omsætningsaktiver kan dække de kortfristede gældsforpligtelser.</p>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="soliditet">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    5. Virksomhedens soliditet
                  </span>
                </div>
                <div className="tip-step-content" id="accr-5">
                  <p>Virksomhedens soliditet udtrykker virksomhedens forhold mellem egenkapital og fremmedkapital og knytter sig til virksomhedens finansiering. Virksomhedens soliditet antyder virksomhedens evne til at modstå eventuelle tab, og ud fra et kreditorhensyn anses en egenkapitalsfinansiering på 40 procent (udtrykt ved en soliditetsgrad på 40%) som værende et tilfredsstillende niveau.</p>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="rentabilitet">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    6. Virksomhedens rentabilitet
                  </span>
                </div>
                <div className="tip-step-content" id="accr-6">
                  <p>Rentabilitet knytter sig til virksomhedens evne til at forrente den investeret kapital og skabe afkast. Virksomhedens rentabilitet udgøres af følgende to forhold: </p>
                  <ul>
                    <li>Virksomhedens indtjeningsevne, der udtrykker virksomhedens evne til at skabe indtjening på baggrund af virksomhedens omsætning</li>
                    <li>Kapitaltilpasningsevnen, der udtrykker virksomhedens evne til at tilpasse aktivitetsniveauet i forhold til den investeret kapital, der er til rådighed.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="udviklingen">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    7. Udviklingen i virksomhedens antal af ansatte
                  </span>
                </div>
                <div className="tip-step-content" id="accr-7">
                  <p>En indflydelsesrig indikator for virksomhedens sunde og organiske vækst er udviklingen i virksomhedens antal af ansatte. Kontinuerligt stigende antal ansatte år efter år vil ofte være et tegn på, at virksomheden har en sund vækst, hvilket har en positiv indflydelse på kreditvurderingen.</p>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="specifikke">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    8. Branche/industri-specifikke forhold
                  </span>
                </div>
                <div className="tip-step-content" id="accr-8">
                  <p>En kreditvurdering bør typisk suppleres med en industrianalyse, der skal indfange forhold, der påvirker virksomhedens systematiske risiko for konkurs. Hensigten er, at industrianalysen giver yderligere indsigt i debitors risiko for insolvens ved blot at kigge på udviklingen og det generelle risikoniveau i den branche/industri, som den pågældende debitor opererer i. Her gælder det fx., at risikoen for insolvens øges ved generelt fald i efterspørgslen i sektoren og vice versa ved øget aktivitet. Reduktion i brancheaktiviteten vil typisk afspejles i fremtidig indtjening. Derudover skal man være opmærksom på, at nogle industrier kræver kapitaltunge investeringer, som influerer finansieringen og kapitaltilpasningsevnen.</p>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="platforme">
              <div className="tip-step-box">
                <div className="tip-step-header">
                  <span>
                    9. Vær opmærksom på flere platforme
                  </span>
                </div>
                <div className="tip-step-content" id="accr-9">
                  <p>Virksomheder med en dårlig økonomi vil have incitamenter til at fifle med tallene, når de skal indberette tal, hvilket fx kan omfatte momsindberetning. Dette forhold kan let kontrolleres ved at se, om virksomheden har en statsautoriseret revisor. Har virksomheden en statsautoriseret revisor, er man sikret på, at alle tal er korrekt indberettet.</p>
                </div>
              </div>
            </div>
            <div className="tip-step-item" id="transaktionsmønster">
              <div className="tip-step-box">
                <div className="tip-step-header" name="accr-10">
                  <span>
                    10. Virksomhedens transaktionsmønster
                  </span>
                </div>
                <div className="tip-step-content" id="accr-10">
                  <p>Data vedrørende kundens forventelige transaktionsmønster er et enormt indsigtsfuldt datapunkt, men denne type af data kan typisk kun blive indsamlet fra virksomhedens egne salgs- og regnskabsafdeling. Dataen vil kunne give indblik i virksomhedens days sales outstanding (DSO), der udtrykker betalingernes omfang samt det gennemsnitlige </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="help" id="help">
        <div className="container">
          <div className="help-wrapper">
            <div className="help-desc">
              <div className="help-header">
                <h2>Få hjælp til kreditvurdering og fokuser på din forretning</h2>
                <div className="help-header-img"><img src="assets/img/square.svg" alt="kreditvurdering barometer med høj risiko" /></div>
              </div>
              <div className="help-content">
                <div className="help-left-content">
                  <p>Der er mange forhold at holde øje med, når der skal eksekveres ordentlige kreditvurderinger, der løbende skal opdateres, så vurderingen forbliver retvisende.</p>
                  <p>Et godt eksempel på udfordringen med løbende vurderinger er Corona-krisens indvirken på fødevareindustrien. En leverandør af fødevarer til restauranter, som normalt har en kreditscore på 10*, opnår pludselig en kreditscore på 3, da alle restauranter pludselig blev lukket pga. corona. Nu skal du som virksomhed beslutte, om du vil ændre dine kreditbetingelser eller helt stoppe samarbejdet. Det er derfor essentielt, at der monitoreres, altså; at der følges med i markedet hos dine kunder, da de kan ændre kreditværdighed og kreditscore over tid. </p>
                </div>
                <div className="help-right-content">
                  <p>Der findes kreditvurderingsplatforme, som yder løbende kreditvurdering, som automatisk opdateres. Et eksempel på dette er kreditvurderingsvirksomheden, Risika.com, der anvender en algoritme, som tager højde for nøgletal, branche, konkurser og meget mere. Således kan du fokusere på at opretholde og bygge profitable kundrelationer, imens kreditvurderingen foretages andetsteds. </p>
                  <p>*10 er max score på en kreditvurdering. Er en kreditscore på 7 eller over er virksomheden i kategorien ‘Lav risiko for konkurs’.</p>
                  <a href="https://risika.com/da/funktioner/credit-rating/" title="Credit Rating" target="_blank" rel="noreferrer">Få hjælp til kreditvurdering - Klik her</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="finance" id="finance">
        <div className="container">
          <div className="finance-wrapper">
            <div className="finance-img">
              <img src="assets/img/Risika.png" alt="visualisering af kreditvurdering platform" />
            </div>
            <div className="finance-desc">
              <h2>Få minimeret jeres økonomiske risiko</h2>
              <p>
                Har du spørgsmål til kreditvurderinger, eller ønsker du hjælp til at finde en virksomheds kreditscore så klik ind på
                <a href="https://www.risika.com" target="_blank" rel="noreferrer"> Risika.com </a>
                eller ring
                <a href="tel:+45 42 905 757"> +45 42 90 57 57</a>
                .
              </p>
              <a className="cta-link" href="https://www.risika.com" target="_blank" rel="noreferrer">risika.com</a>
            </div>
          </div>
        </div>
      </section>
      <section className="book-now">
        <div className="container">
          <div className="book-wrapper">
            <div className="book-heading">
              <h2>Skab sikkerhed i samhandel via retvisende og præcise kreditvurderinger.</h2>
            </div>
            <div className="book-btn">
              <a className="cta-link" href="https://risika.com/book-demo/" target="_blank" rel="noreferrer">Book demo</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

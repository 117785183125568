import React from 'react';
import './App.scss';
import Home from './pages/Home';

// eslint-disable-next-line func-names
const App = function () {
  return (
    <Home />
  );
};

export default App;

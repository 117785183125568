/* eslint-disable max-len */
import React from 'react';
import '../App.scss';

// eslint-disable-next-line func-names
const Footer = function () {
  return (
    <footer>
      <div className="container-fluid">
        <div className="footer-wrapper">
          <div className="footer-heading">
            <div className="footer-socials">
              <ul>
                <li>
                  <a href="https://www.linkedin.com/company/risika/" title="LinkedIn" target="_blank" rel="noreferrer">
                    <img src="assets/img/linkedin.svg" alt="linkedin" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/risikadk" title="Facebook" target="_blank" rel="noreferrer">
                    <img src="assets/img/facebook.svg" alt="facebook" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom-footer">
            <p>Risika A/S er et dansk kreditvurderingsbureau, godkendt af Datatilsynet, der leverer kreditoplysninger og virksomhedsinformationer på alle virksomheder i Skandinavien</p>
            <a href="https://risika.com/" title="Risika" target="_blank" rel="noreferrer">risika.com</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

/* eslint-disable max-len */
import React, { useEffect } from 'react';
import '../App.scss';

// eslint-disable-next-line func-names
const Header = function () {
  useEffect(() => {
    const element = document.querySelector('.menu-icon');
    const sidemenu = document.querySelector('.inner');
    let sidebarstatus = false;

    const ToggleSidebar = () => {
      if (sidebarstatus === false) {
        sidebarstatus = true;
        document.querySelector('.header-links').classList.add('show');
        document.querySelector('body').style.overflow = 'hidden';
      } else {
        sidebarstatus = false;
        document.querySelector('.header-links').classList.remove('show');
        document.querySelector('body').style.overflow = 'auto';
      }
    };
    element.addEventListener('click', ToggleSidebar);
    sidemenu.addEventListener('click', ToggleSidebar);
  });
  return (
    <header id="banner1">
      <div className="container-fluid">
        <div className="header">
          <div className="header-logo">
            <a href="#banner" title="Risika"><img src="assets/img/logo.svg" alt="risika" /></a>
          </div>
          <div className="hamburger-menu-button">
            <div className="hamburger-menu menu-icon" />
          </div>
          <div className="header-links">
            <div className="hamburger-menu-button">
              <div className="hamburger-menu open inner" />
            </div>
            <ul className="main-links">
              <li>
                <a href="#a">Kreditvurdering</a>
                <ul className="sub-menu">
                  <li>
                    <a href="#b2b-branchen">Hvad betyder kreditvurdering i B2B-branchen</a>
                  </li>
                  <li>
                    <a href="#hvorfor">Hvorfor er hyppige kreditvurderinger vigtige for B2B-virksomheder?</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#a">10 gode råd</a>
                <ul className="sub-menu">
                  <li>
                    <a href="#virksomhedens">1. Virksomhedens alder</a>
                  </li>
                  <li>
                    <a href="#regnskabsdata">2. Se nærmere på virksomhedens regnskabsdata</a>
                  </li>
                  <li>
                    <a href="#struktur">3. Virksomhedens struktur</a>
                  </li>
                  <li>
                    <a href="#likviditet">4. Virksomhedens likviditet</a>
                  </li>
                  <li>
                    <a href="#soliditet">5. Virksomhedens soliditet</a>
                  </li>
                  <li>
                    <a href="#rentabilitet">6. Virksomhedens rentabilitet</a>
                  </li>
                  <li>
                    <a href="#udviklingen">7. Udviklingen i virksomhedens antal af ansatte</a>
                  </li>
                  <li>
                    <a href="#specifikke">8. Branche/industri-specifikke forhold</a>
                  </li>
                  <li>
                    <a href="#platforme">9. Vær opmærksom på flere platforme</a>
                  </li>
                  <li>
                    <a href="#transaktionsmønster">10. Virksomhedens transaktionsmønster</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#a">Hjælp</a>
                <ul className="sub-menu">
                  <li>
                    <a href="#help">Få hjælp til kreditvurdering og fokuser på din forretning</a>
                  </li>
                  <li>
                    <a href="#finance">Få minimeret jeres økonomiske risiko</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul className="risika-btn">
              <li>
                <a href="https://risika.com/" title="Risika" target="_blank" rel="noreferrer">risika.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
